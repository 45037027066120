<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
      :with-header="false">
      <div class="drawer_box">
        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearch">查询
          </el-button>
          <el-button size="small" type="primary" plain @click="onReset">重置
          </el-button>

        </el-row>
        <el-divider style="width: 100%"></el-divider>
        <el-form :model="searchForm" ref="queryForm" label-width="10px">
          <el-row>分字段搜索</el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="searchForm.title" size="mini" placeholder="耗材名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-select v-model="searchForm.zc_gztype" size="mini" placeholder="购置方式">
                  <el-option v-for="(item, index) in purchaseType" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="labelCss">
              <el-form-item label="价值区间:" label-width="80px">
                <div class="flexStart">
                  <el-input v-model="searchForm.jine1" size="mini" placeholder="最小值"></el-input>
                  <span style="margin: 0 10px;">-</span>
                  <el-input v-model="searchForm.jine2" size="mini" placeholder="最大值"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>筛选</el-row>
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item label="">
                <el-cascader ref="myCascader1" v-model="searchForm.zc_typeid" :options="typeList"
                             size="mini" :props="optionProps1" :show-all-levels="true" filterable
                             placeholder="耗材分类" @change="handleCascaderChange1"></el-cascader>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="">
                <el-select v-model="searchForm.status" size="small" placeholder="状态"
                  style="width: 150px!important;margin-left: 10px;">
                  <el-option label="待审核" :value="0"></el-option>
                  <el-option label="通过" :value="1"></el-option>
                  <el-option label="驳回" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="申领日期" label-width="80px">
                <el-date-picker size="small" style="width: 100%;" v-model="searchForm.dateRange" type="daterange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>


          </el-row>
        </el-form>
      </div>
    </el-drawer>


    <el-row v-if="pageFrom != 'chuku'" style="margin-bottom: 10px;" class="flexBetween flex_1">
      <div class="flex_1">
        <el-button v-if="pageFrom != 'chuku'" size="small" type="primary" plain icon="el-icon-plus"
          @click="add">新增</el-button>

        <el-input v-model="searchForm.title" size="small" clearable style="width: 300px!important;margin-left: 10px;"
          placeholder="模糊搜索">
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>


        <el-date-picker v-if="pageFrom != 'chuku'" size="small" style="width: 300px;margin-left: 10px;"
          v-model="searchForm.dateRange" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>

        <el-select v-if="pageFrom != 'chuku'" size="small" v-model="searchForm.sp_status" placeholder="状态"
          style="width: 150px!important;margin-left: 10px;">
          <el-option label="待审核" value="0" style="color: #1289ff;"></el-option>
          <el-option label="审核通过" value="1" style="color: #67c23a;"></el-option>
          <el-option label="审核驳回" value="2" style="color: #f56c6c;"></el-option>
        </el-select>

        <el-button size="small" icon="el-icon-search" @click="onSearch" style="margin-left: 10px;" type="primary"
          plain>查询</el-button>
        <el-button size="small" icon="el-icon-search" @click="onReset" style="margin-left: 10px;" type="warning"
          plain>重置</el-button>
      </div>

      <div>
        <el-button size="small" icon="el-icon-download" type="primary" plain style="" @click="exportExcel">导出
        </el-button>
        <el-popover ref="mypopover" placement="right-start" width="240" trigger="click" title="导出xlsx表格列配置">
          <div class="" style="width:100%;padding: 10px 0;border-top: 1px solid #efefef;">
            <div class="flexBetween" v-for="(item, index) in exportFields" style="margin-top: 20px;">
              <span>{{ item.name }}</span>
              <el-switch :disabled="item.disabled" v-model="item.value" active-color="#2469f3">
              </el-switch>
            </div>
          </div>

        </el-popover>

        <el-button size="small" v-popover:mypopover type="primary" plain icon="el-icon-setting"
          style="margin: 0;"></el-button>


        <el-button v-if="false && pageFrom != 'chuku'" type="text" @click="drawer = true"
          style="color: #999999;margin-left: 20px;">
          <i class="el-icon-s-operation"></i>&nbsp;高级搜索
        </el-button>
      </div>
    </el-row>

    <el-row v-if="pageFrom == 'chuku'" style="margin-bottom: 10px;" class="flexBetween flex_1">
      <div class="flex_1">

        <el-input size="small" v-model="searchForm.title" clearable style="width: 300px!important;" placeholder="模糊搜索">
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>




        <el-select size="small" v-model="searchForm.ck_status" placeholder="状态"
          style="width: 150px!important;margin-left: 10px;">
          <el-option label="待出库" value="0" style="color: #1289ff;"></el-option>
          <el-option label="已出库" value="1" style="color: #67c23a;"></el-option>
          <el-option label="驳回" value="2" style="color: #f56c6c;"></el-option>
        </el-select>

        <el-button size="small" icon="el-icon-search" @click="onSearch" style="margin-left: 10px;" type="primary"
          plain>查询</el-button>
        <el-button size="small" icon="el-icon-search" @click="onReset" style="margin-left: 10px;" type="warning"
          plain>重置</el-button>
        <el-button v-if="ckBatchBtnVisible" size="small" type="primary" plain @click="ckbatch">一键出库
        </el-button>
      </div>

      <div>
        <el-button size="small" icon="el-icon-download" type="primary" plain style="" @click="exportExcel">导出
        </el-button>
        <el-popover ref="mypopover" placement="right-start" width="240" trigger="click" title="导出xlsx表格列配置">
          <div class="" style="width:100%;padding: 10px 0;border-top: 1px solid #efefef;">
            <div class="flexBetween" v-for="(item, index) in exportFields" style="margin-top: 20px;">
              <span>{{ item.name }}</span>
              <el-switch :disabled="item.disabled" v-model="item.value" active-color="#2469f3">
              </el-switch>
            </div>
          </div>

        </el-popover>

        <el-button size="small" v-popover:mypopover type="primary" plain icon="el-icon-setting"
          style="margin: 0;"></el-button>



      </div>
    </el-row>

    <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange"
      style="width: 100%;" stripe border>

      <el-table-column type="selection" width="55" fixed="left" />
      <el-table-column type="index" label="序号" width="50" fixed="left" />
      <el-table-column prop="sl_num" label="申领单号" width="150" :show-overflow-tooltip="true" fixed="left" />
      <el-table-column prop="sp_status" label="单据状态" width="130" :show-overflow-tooltip="true" fixed="left">
        <template slot-scope="scope">

          <el-tag size="mini" type="primary" v-if="scope.row.sp_status == 0">待审核</el-tag>

          <el-tag size="mini" type="warning" v-if="scope.row.sp_status == 1 && scope.row.ck_status == 0">待出库</el-tag>
          <el-tag size="mini" type="success" v-if="scope.row.sp_status == 1 && scope.row.ck_status == 1"
            style="margin-right: 6px;">已出库</el-tag>

          <el-tag size="mini" type="warning"
            v-if="scope.row.sp_status == 1 && scope.row.ck_status == 1 && (!scope.row.qs_json || !scope.row.qs_json.qs_userid)">待签收</el-tag>
          <el-tag size="mini" type="success"
            v-if="scope.row.sp_status == 1 && scope.row.ck_status == 1 && (scope.row.qs_json && scope.row.qs_json.qs_userid)">已签收</el-tag>

          <el-tag size="mini" type="danger" v-if="scope.row.sp_status == 2">审核驳回</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="username" label="申请人" width="120" />
      <el-table-column prop="dept_name" label="所在部门" width="120" />
      <el-table-column prop="title" label="申领物品" width="250">
        <template slot-scope="scope">
          <el-tooltip :enterable="false" :disabled="!scope.row.desc || (scope.row.desc && scope.row.desc.length < 28)"
            class="item" effect="dark" placement="top-start">
            <div slot="content" style="width: 400px;">{{ scope.row.desc }}</div>
            <div @click="view(scope.row)" style="color: #409EFF;cursor: pointer;width: 230px;" class="overOneLine">
              {{ scope.row.desc }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="cdate" label="申领时间" width="180">
        <template slot-scope="scope">
          <span style="font-size: 14px;">{{ scope.row.cdate }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="cdate" label="审核时间" width="180" align="center">
        <template slot-scope="scope">
          <span style="font-size: 14px;" v-if="scope.row.sp_date && scope.row.sp_status != 0">{{ scope.row.sp_date
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sl_remark" label="申领原因" min-width="160" :show-overflow-tooltip="true" />


      <el-table-column label="操作" min-width="200" fixed="right">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-tag size="mini" style="cursor: pointer" @click="view(scope.row)">查看</el-tag>
            <el-tag v-if="pageFrom != 'chuku' && scope.row.sp_status == 0 && userinfo.id == scope.row.create_uid"
              size="mini" style="margin-left: 10px;cursor: pointer;color: #409eff;" @click="edit(scope.row)"><i
                class="el-icon-edit"></i></el-tag>
            <el-tag v-if="pageFrom != 'chuku' && scope.row.sp_status == 0 && userinfo.id == scope.row.create_uid"
              size="mini" type="danger" style="cursor: pointer;margin-left: 10px;" @click="removeItem(scope.row)"><i
                class="el-icon-delete"></i></el-tag>
            <el-tag v-if="pageFrom != 'chuku' && scope.row.icansp" type="warning" size="mini"
              style="margin-left: 10px;cursor: pointer;" @click="view(scope.row, 'verify')">审核</el-tag>

            <el-tag v-if="pageFrom == 'chuku' && scope.row.sp_status == 1 && scope.row.ck_status == 0" type="warning"
              size="mini" style="margin-left: 10px;cursor: pointer;" @click="view(scope.row, 'verify')">出库</el-tag>
            <el-tag size="mini" type="success" style="cursor: pointer;margin-left: 10px;"
              @click="print(scope.row)">打印</el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
      :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
      layout="total,sizes, prev, pager, next" :total="page.count">
    </el-pagination>

    <!--导出-->
    <ExportTable :exportList="exportList" :exportColumns="exportColumns"></ExportTable>


  </div>
</template>

<script>
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export default {
  components: {
    ExportTable: () => {
      return import('../../components/ExportTable.vue')
    },
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      exportFields: [
        { name: '申领单号', field: 'sl_num', value: true, disabled: true, },
        { name: '单据状态', field: 'sp_status_Name', value: true, disabled: false, },
        { name: '申领人', field: 'username', value: true, disabled: true, },
        { name: '申领时间', field: 'cdate', value: true, disabled: false, },
        { name: '申领项目描述', field: 'desc', value: true, disabled: false, },
        { name: '申领原因', field: 'sl_remark', value: true, disabled: false, },
      ],
      exportColumns: [],
      exportList: [],
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
      typeList: [],
      areaList: [],
      optionProps: { value: 'id', label: 'storename', emitPath: false, checkStrictly: true },
      optionProps1: { value: 'id', label: 'cname', emitPath: false },
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      dialogVisible_withdraw: false,
      ckBatchBtnVisible: false,//一键入库按钮
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      purchaseType: [],
      pinpaiList: [],
      xinghaoList: [],
      sxDataList: [],
      //--审批流程用----
      process_code: "hcsl",
      process_id: 0,
    }
  },
  props: ['pageFrom'],
  mounted() {
    console.log(this.pageFrom)
    this.getCreateUser()

    this.getList();

    this.getSXList();
    this.getType();
    this.getArea();
    this.getCurDate();

    this.getPurchaseType()
    this.getPinPaiList()
    this.getXinghaoList()
    this.getProcessID()
  },
  methods: {
    getExportList() {

      return new Promise(resolve => {
        let _this = this
        let params = {}, arr = []

        params.title = _this.searchForm.title
        params.page = 1
        params.pagesize = 10000000000
        params.type = 'sx'
        params.zc_type = 'sx'
        params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
        params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
        params.sp_status = _this.searchForm.sp_status
        params.sl_type = 2

        let url = "/api/zc_hc_sl_list"
        if (this.pageFrom == 'chuku') {
          params.sp_status = 1
          params.ck_status = _this.searchForm.ck_status
          url = "/api/zc_hc_ck_list"
        }
        this.$http.post(url, params).then(res => {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map(a => {
              if (a.sp_status == 0) { a.sp_status_Name = '待审核' }
              if (a.sp_status == 1) { a.sp_status_Name = '审核通过' }
              if (a.sp_status == 2) { a.sp_status_Name = '审核驳回' }
              a.match = 1
              a.hcListLen = 0
              if (a.hc_list) {
                a.list = JSON.parse(a.hc_list)
                a.desc = `${a.dept_name}${a.username} 申领 `
                a.list && a.list.map(h => {
                  a.desc = `${a.desc}${h.title || '-'}${h.slNum || '-'}${h.jldw};`
                  if (h.cgNum != h.rkNum) {
                    a.match = 0
                  }
                })
                a.listLen = a.list.length
              }
            })
            this.exportList = res.data.data
          }
          resolve()
        })

      })
    },

    exportExcel() {
      console.log(this.exportFields)
      const loading = this.$loading({
        lock: true,
        text: '数据准备中，请稍等...',
        spinner: 'el-icon-loading',
      });
      let _this = this

      if (this.multipleSelection && this.multipleSelection.length > 0) {
        loading.close();
        this.exportList = this.multipleSelection
        _this.preSaveExcel()
      } else {
        this.getExportList().then(r => {
          loading.close();
          _this.preSaveExcel()

        })
      }
      setTimeout(() => {
        loading.close();
      }, 2000);
    },
    preSaveExcel() {
      let _this = this
      let arr = []
      this.exportFields.map(a => {
        if (a.value) {
          arr.push(a)
        }
      })
      this.exportColumns = arr
      if (!this.exportList || this.exportList.length == 0) {
        this.$message.warning('暂无数据可导出~')
      }
      setTimeout(function () {
        _this.saveExcel('申领列表导出')
      }, 500)
    },
    saveExcel(saveTitle) {
      let _this = this
      var xlsxParam = { raw: true }
      let wb = XLSX.utils.table_to_book(document.querySelector('#exportTable'), xlsxParam);
      let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${saveTitle ? saveTitle : "导出"}${_this.curDate}.xlsx`);
      } catch (e) {
        if (typeof console !== 'undefined')
          console.log(e, wbout)
      }
      return wbout
    },

    getCreateUser() {

      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
        this.$http.post("/api/is_store_admin", { uid: this.userinfo.id }).then(res => {
          this.ckBatchBtnVisible = res.data.boolean;
        })
      })
    },
    getList() {
      let _this = this
      _this.DataList = []
      let params = {}, arr = []
      params.sl_type = 2
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      params.zc_type = 'sx'
      params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
      params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
      params.sp_status = _this.searchForm.sp_status

      let url = "/api/zc_hc_sl_list"
      if (this.pageFrom == 'chuku') {
        params.sp_status = 1
        params.ck_status = _this.searchForm.ck_status
        url = "/api/zc_hc_ck_list"
      }
      this.$http.post(url, params).then(res => {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map(a => {
            if (a.sp_status == 0) { a.sp_status_Name = '待审核' }
            if (a.sp_status == 1) { a.sp_status_Name = '审核通过' }
            if (a.sp_status == 2) { a.sp_status_Name = '审核驳回' }
            a.match = 1
            a.listLen = 0
            if (a.hc_list) {
              a.list = JSON.parse(a.hc_list)
              a.desc = ``
              a.list && a.list.map(h => {
                a.desc = `${a.desc}${h.title || '-'}${h.slNum || '-'}${h.jldw};`
                if (h.cgNum != h.rkNum) {
                  a.match = 0
                }

              })
              a.listLen = a.list.length
            }
            if (a.qs_json) {
              a.qs_json = JSON.parse(a.qs_json)
            }

            if (a.process_json) {
              try {
                let process_json = JSON.parse(a.process_json)
                console.log(process_json);
                for (let p of process_json) {
                  if (p.sp_users) {
                    for (let u of p.sp_users) {
                      if (u.sptime) {
                        a.sp_date = u.sptime
                      }

                    }
                  }

                }
              } catch (error) {

              }
            }
          })
          setTimeout(function () {
            _this.DataList = res.data.data;

          }, 50)
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },
    getSXList() {
      let _this = this
      let params = {}, arr = []


      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      this.$http.post("/api/sx_hc_list", params).then(res => {
        this.sxDataList = res.data.data;
        this.page1 = res.data.page
        this.page_hc1 = res.data.page
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", { key_type: "purchaseType" }).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    getPinPaiList() {
      this.$http.post("/api/sx_hc_pinpai", { zc_typeid: this.searchForm.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.pinpaiList = res.data
        }
      })
    },
    getXinghaoList() {
      this.$http.post("/api/sx_hc_xinghao", { zc_typeid: this.EditItem.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.xinghaoList = res.data
        }
      })
    },
    //一键出库
    ckbatch() {
      // const arr = [];
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const item = this.multipleSelection[i];
          if (item.ck_status == "1") {
            this.$message.error('选择的申领单中有已出库的!!!')
            return
          }
          // arr.push({ id: item.id, sp_status: 1, sp_remark: item.sp_remark })
        }

        if (!this.check_ck_admin(this.multipleSelection)) {
          this.$message.error('选择的申领单中有您没权限出库的!!!')
          return
        }

        this.$confirm(`是否确认出库?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/api/get_sign_pic").then(res => {
            if (res.data && res.data.handsign) {
              this.multipleSelection.forEach(item => {
                item.hc_list = item.list;
                item.process_id = this.process_id;
                item.hc_list.map((hc) => {
                  hc.signUrl = res.data.handsign;
                  hc.ck_username = this.userinfo.username;
                  hc.ckNum = hc.slNum;
                })
              });
              console.log(this.multipleSelection);
              this.$http.post("/api/zc_hc_sl_check_batch", { data: this.multipleSelection }).then(res => {
                this.$message({
                  type: 'success',
                  message: `出库操作成功！`
                });

                this.$parent.$refs.SXchukuTable.getList()
              })
            } else {
              this.$message.error("请先在小程序设置个人签名")
              return
            }
          })
        }).catch(() => {

        });
      } else {
        this.$message.error('未选择申领单!!!')
      }
    },
    //判断是否是所选申领单中的耗材仓库管理员,只要其中有一项不是某个耗材仓库管理员就返回false
    check_ck_admin(arr) {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          for (let j = 0; j < item.list.length; j++) {
            const hc_item = item.list[j];
            const is_ck = await this.check_ck(hc_item.id);
            if (!is_ck) {
              resolve(false)
              return;
            }
          }
        }
        resolve(true)
      })

    },
    //根据耗材id判断是否是仓库管理员
    check_ck(id) {
      return new Promise(resolve => {
        this.$http.post("/api/is_ck_admin", {
          id: id,
        }).then(res => {
          resolve(res.data.boole)
        })
      })

    },

    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    // 新增
    add() {
      this.$$parent(this, 'add').then(r => {
      })
    },
    editItem(row, flag) {
      this.$$parent(this, 'editItem', row, flag).then(r => {
      })

    },
    removeItem(item) {
      this.$$parent(this, 'removeItem', item).then(r => {
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    delSlct() {
      this.$$parent(this, 'delSlct', this.multipleSelection).then(r => {
      })
    },
    edit(row, type) {
      this.$$parent(this, 'editItem', row).then(r => { })
    },
    view(row, type) {
      this.$$parent(this, 'view', row, type).then(r => { })
    },
    qianshou(row) {
      this.$$parent(this, 'qianshou', row).then(r => { })
    },
    print(row) {
      this.$$parent(this, 'print', row).then(r => { })
    },
    //导入
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
    uploadAttch(e) {
      this.$$parent(this, 'uploadAttch', e).then(r => {
      })
    },

    //增加库存
    showStore(row) {
      this.$$parent(this, 'showStore', row).then(r => {
      })
    },


    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.searchForm = {}
      this.page.current_page = 1
      this.getList()
    },

    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },


    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'sx' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },

    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },

    handleCascaderChange(e) {
      console.log(e)
    },
    handleCascaderChange1(e) {
      if (e && this.searchForm.zc_typeid) {
        this.getPinPaiList()
        this.getXinghaoList()
      }
    },

    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },

    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },

    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
  }
}
</script>

<style lang="less" type="text/scss">
.pagezclist {
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>
